import styled, { keyframes } from "styled-components";

// Define a blinking animation
const blink = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
`;

// Styled component for the blinking circle
export const Blink = styled.div<{ size: string; color: string }>`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  background-color: ${(props) => props.color};
  border-radius: 50%;
  animation: ${blink} 1s infinite;
`;
