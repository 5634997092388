import React, { useState } from "react";
import styled from "styled-components";
import { COLORS } from "./Theme";

// Styled components for the Select UI
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledInput = styled.input`
  padding: 10px;
  border-radius: 24px;
  border: 1px solid ${COLORS.primary};
  width: 100%;
  box-sizing: border-box;
`;

const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid ${COLORS.primary};
  border-radius: 8px;
  max-height: 150px;
  overflow-y: auto;
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 10;
`;

const Option = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.primary};
    color: white;
  }
`;

type Props = {
  options: string[];
  placeholder?: string;
  onChange?: (selected: string) => void;
  value?: string;
  style?: React.CSSProperties;
};

export const Select: React.FC<Props> = ({
  options,
  placeholder,
  onChange,
  value,
  style,
}) => {
  const [search, setSearch] = useState(value || "");
  const [filteredOptions, setFilteredOptions] = useState<string[]>(options);
  const [isOpen, setIsOpen] = useState(false);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearch(value);

    // Filter options based on search
    const filtered = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredOptions(filtered);
  };

  const handleOptionClick = (option: string) => {
    setSearch(option);
    setIsOpen(false);

    // Call onChange if provided
    if (onChange) {
      onChange(option);
    }
  };

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  return (
    <SelectContainer style={style}>
      <StyledInput
        type="text"
        value={search}
        placeholder={placeholder || "חפש..."}
        onChange={handleSearchChange}
        onClick={toggleDropdown}
      />
      {isOpen && (
        <Dropdown>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <Option key={index} onClick={() => handleOptionClick(option)}>
                {option}
              </Option>
            ))
          ) : (
            <Option>אין אופציה כזאת</Option>
          )}
        </Dropdown>
      )}
    </SelectContainer>
  );
};
