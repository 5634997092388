import styled from "styled-components";

type Props = {
  column?: boolean;
  justify?: "center" | "start" | "end" | "between" | "around" | "evenly";
  align?: "center" | "start" | "end" | "stretch" | "baseline";
  gap?: string;
  w100?: boolean;
  h100?: boolean;
  flexWrap?: boolean;
  padding?: string;
  position?: "static" | "relative" | "absolute" | "sticky" | "fixed";
};

export const fixCSSNames = (name?: string) => {
  return name
    ?.replace("start", "flex-start")
    ?.replace("end", "flex-end")
    ?.replace("between", "space-between")
    ?.replace("around", "space-around")
    ?.replace("evenly", "space-evenly");
};

export const Flex = styled.div<Props>`
  display: flex;
  flex-direction: ${({ column }) => (column ? "column" : "row")};
  justify-content: ${({ justify }) => fixCSSNames(justify) || "flex-start"};
  align-items: ${({ align }) => fixCSSNames(align) || "stretch"};
  gap: ${({ gap }) => gap || "0"};
  width: ${({ w100 }) => (w100 ? "100%" : "auto")};
  height: ${({ h100 }) => (h100 ? "100%" : "auto")};
  flex-wrap: ${({ flexWrap }) => (!!flexWrap ? "wrap" : "nowrap")};
  padding: ${({ padding }) => padding || ""};
  position: ${({ position }) => (position ? position : "static")};
`;
