import styled from "styled-components";
import { COLORS } from "./Theme";

export const TextButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 11px;
  color: ${COLORS.primary};
  padding: 4px;
  border-radius: 12px;
  &:hover:enabled,
  &:active:enabled {
    background-color: ${COLORS.primary50};
  }
`;
type ButtonVariant = "primary" | "secondary" | "danger" | "gray";
type ButtonProps = {
  variant: ButtonVariant;
  size?: "small" | "medium";
};
export const MainButton = styled.button<ButtonProps>`
  font-size: ${({ size }) => (size === "small" ? "12px" : "16px")};
  padding: ${({ size }) => (size === "small" ? "8px 16px" : "12px 24px")};
  margin: 20px 0;
  background-color: ${({ variant }) =>
    variant === "primary"
      ? COLORS.primary
      : variant === "secondary"
      ? COLORS.secondary
      : variant === "gray"
      ? COLORS.gray
      : COLORS.danger};
  color: white;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  &:hover:enabled,
  &:active:enabled {
    background-color: ${({ variant }) =>
      variant === "primary"
        ? COLORS.primary50
        : variant === "secondary"
        ? COLORS.secondary50
        : variant === "gray"
        ? COLORS.gray800
        : COLORS.danger800};
  }
  &:disabled {
    background-color: ${COLORS.gray};
  }
`;
