import React, { useState } from "react";
import styled from "styled-components";

// Styled components
const MenuContainer = styled.div`
  position: relative;
`;

const HamburgerButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  div {
    height: 3px;
    width: 100%;
    background-color: black;
    border-radius: 2px;
  }
`;

const PopupMenu = styled.div<{ open: boolean }>`
  position: absolute;
  top: 20px;
  right: 12px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 100;
`;

const MenuItem = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #f9f9f9;
  }
`;

// Component
export const HamburgerMenu: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <MenuContainer>
      <HamburgerButton onClick={toggleMenu}>
        <div />
        <div />
        <div />
      </HamburgerButton>

      <PopupMenu open={menuOpen}>
        <MenuItem
          onClick={() => {
            document.location.assign("/");
          }}
        >
          ש"ג ג'וליס
        </MenuItem>
        <MenuItem
          onClick={() => {
            document.location.assign("/shifts");
          }}
        >
          תורנויות
        </MenuItem>
      </PopupMenu>
    </MenuContainer>
  );
};
