import { Shift } from "../types/shifts";

export const heDays = [
  "ראשון",
  "שני",
  "שלישי",
  "רביעי",
  "חמישי",
  "שישי",
  "שבת",
];
const shiftsIndexes = [
  2, 3, 4, 6, 7, 8, 10, 11, 12, 14, 15, 16, 18, 19, 20, 22, 23, 24, 26, 27, 28,
];
export const fetchShifts = async () => {
  const url =
    "https://docs.google.com/spreadsheets/d/1sbQ_f4P8w7-sNS11OE3YJODGdNq8nBoG/gviz/tq?tqx=out:json";
  return (await fetch(url)).text().then((data) => {
    return JSON.parse(data.substring(47, data.length - 2) as any).table.rows;
  });
};

export const parseShiftData = (rows: any[]) => {
  const shifts: Shift[] = [];
  let currentLocation = ""; // Tracks the current location
  let dateIndexes: Record<number, string> = {};
  for (let row of rows) {
    const firstColumn = row.c[0]?.v as string;

    if (
      firstColumn &&
      (firstColumn.startsWith("שעות") || firstColumn.startsWith("לוח"))
    ) {
      continue; // Skip header row
    }

    // handle location row
    if (firstColumn && firstColumn.startsWith("מחנה")) {
      currentLocation = firstColumn.split(" ")[1];
      continue;
    }

    // save date row
    if (
      row.c.some(
        (cell: any) => cell?.v && heDays.some((day) => cell.v.includes(day))
      )
    ) {
      var date = "";
      for (let i = 0; i < row.c.length; i++) {
        if (row.c[i]?.v && heDays.some((day) => row.c[i].v.includes(day))) {
          const lengthOfWords = row.c[i].v.split(" ").length;
          date = row.c[i].v.split(" ")[lengthOfWords - 1]; // Extract the date
        }
        dateIndexes[i] = date;
      }
      continue;
    }

    var position = "";
    // handel row shifts
    for (let i = 0; i < row.c.length; i++) {
      if (!shiftsIndexes.includes(i)) {
        if (row.c[i]?.v as string) {
          position = row.c[i]?.v;
        }
        continue;
      }
      const personnel = row.c[i]?.v;
      const date = dateIndexes[i];
      const time = row.c[0]?.v;

      if (personnel?.trim() === "ּ") continue;

      if (personnel && position && date && time) {
        const dateObj = convertToDateObject(date);
        const times = convertTimeToTimestamps(dateObj, time);
        shifts.push({
          timeDisplay: time,
          position,
          personnel: replaceName(personnel),
          date: dateObj,
          location: currentLocation.trim(),
          startTime: times.startTimestamp,
          endTime: times.endTimestamp,
        });
      }
    }
  }

  return shifts;
};

function convertToDateObject(dateString: string): Date {
  // Trim any extra spaces
  const trimmedDate = dateString.trim();

  // Split the string into day, month, and year
  const [day, month, year] = trimmedDate.split("/").map(Number);

  // Create and return a new Date object (Note: JavaScript Date expects the year as a full year)
  return new Date(
    `20${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}T00:00:00`
  );
}

function convertTimeToTimestamps(
  currentDate: Date,
  timeRange: string
): { startTimestamp: number; endTimestamp: number } {
  // Split the time range into start and end times
  const [start, end] = timeRange.split("-");

  // Create a Date object for the start time
  const startDate = new Date(currentDate);
  const [startHour, startMinute] = start.split(":").map(Number);
  startDate.setHours(startHour, startMinute, 0, 0); // Set hours, minutes, and seconds

  // Create a Date object for the end time
  const endDate = new Date(currentDate);
  const [endHour, endMinute] = end.split(":").map(Number);
  endDate.setHours(endHour, endMinute, 0, 0); // Set hours, minutes, and seconds

  // If end time is earlier than start time, it means the end time is on the next day
  if (endDate.getTime() <= startDate.getTime()) {
    endDate.setDate(endDate.getDate() + 1); // Add one day
  }

  // Return timestamps
  return {
    startTimestamp: startDate.getTime(),
    endTimestamp: endDate.getTime(),
  };
}

export function replaceName(name: string): string {
  if (name === "ינון (לאחר השמירה חובר לש.ג)") {
    return "ינון";
  }
  if (name === "ינון בלוך") {
    return "ינון";
  }
  if (name === "ינוון בלוך") {
    return "ינון";
  }
  if (name === "יוסף צקול") {
    return "יוסף";
  }

  if (name === "נאור פנירי") {
    return "נאור";
  }

  if (name === "ניר דהן") {
    return "נירו";
  }

  if (name === "נרמוד") {
    return "נמרוד";
  }
  if (name === "עידן רדמן") {
    return "עידו רדמן";
  }
  if (name === "רון חים הלל") {
    return "רון חיים הלל";
  }
  if (name === "אהרון פרידמן") {
    return "אהרון";
  }
  return name.trim();
}
