import React, { useState, useEffect } from "react";
import MaorLogo from "./assets/maor-logo.png";
import { Flex } from "./elements/Flex";
import { MainButton } from "./elements/Buttons";
import { COLORS } from "./elements/Theme";
import { EmanuelGate } from "./components/EmanuelGate";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Shifts } from "./components/Shifts";
import { HamburgerMenu } from "./components/HamburgerMenu";
import { Justice } from "./components/Justice";

// Define the BeforeInstallPromptEvent type
type BeforeInstallPromptEvent = Event & {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
};

const App: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [showInstallButton, setShowInstallButton] = useState(false);
  const [showFallbackInstructions, setShowFallbackInstructions] =
    useState(false);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <EmanuelGate />,
    },
    {
      path: "/shifts",
      element: <Shifts />,
    },
    {
      path: "/justice",
      element: <Justice />,
    },
  ]);

  // Utility function to check if the device is iOS
  const isIOS = (): boolean => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  useEffect(() => {
    // Check if the URL has the "install" parameter
    const urlParams = new URLSearchParams(window.location.search);
    const hasInstallParam = urlParams.has("install");

    // Check if the device is iOS
    const isIosDevice = isIOS();

    // Listen for the `beforeinstallprompt` event
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);

      if (
        !window.matchMedia("(display-mode: standalone)").matches ||
        hasInstallParam
      ) {
        setShowInstallButton(true);
      }
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    // Check if the app is already installed
    const isStandalone =
      window.matchMedia("(display-mode: standalone)").matches ||
      (window.navigator as any).standalone;

    if (!isStandalone) {
      if (isIosDevice) {
        setShowFallbackInstructions(true);
      } else {
        setShowInstallButton(true);
      }
    }

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("PWA installation accepted");
        } else {
          console.log("PWA installation dismissed");
        }
        setDeferredPrompt(null);
        setShowInstallButton(false);
      });
    }
  };

  return (
    <Flex
      column={true}
      justify="center"
      align="center"
      style={{
        textAlign: "center",
        padding: "5px 8px",
      }}
    >
      {showFallbackInstructions && (
        <div
          style={{
            position: "fixed",
            top: "50px",
            right: "5px",
            padding: "5px",
            backgroundColor: COLORS.gray50,
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
        >
          <div>
            כדי להתקין את האפליקציה במכשיר iOS שלך, פתח אותה בדפדפן Safari, לחץ
            על <strong>שתף</strong> ובחר <strong>הוסף למסך הבית</strong>.
          </div>
          <MainButton
            variant="gray"
            size="small"
            onClick={() => setShowFallbackInstructions(false)}
          >
            סגור
          </MainButton>
        </div>
      )}

      <Flex justify="between" align="center" gap="8px" w100>
        <HamburgerMenu />

        <Flex align="center">
          <h4>שועלי ג'וליס</h4>
          <img src={MaorLogo} width={64} height={64} alt="פלוגת הגנה" />
        </Flex>
        {showInstallButton ? (
          <MainButton
            onClick={handleInstallClick}
            variant="secondary"
            style={{
              padding: "4px 6px",
              fontSize: "11px",
            }}
          >
            התקן אפליקציה
          </MainButton>
        ) : (
          <div></div>
        )}
      </Flex>
      <RouterProvider router={router} />
    </Flex>
  );
};

export default App;
