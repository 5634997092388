import React, { useEffect, useState } from "react";
import { Shift } from "../types/shifts";
import { Flex } from "../elements/Flex";
import { COLORS } from "../elements/Theme";
import { Blink } from "../elements/Blink";
import { heDays } from "../utils/shiftsUtils";

type Props = {
  shift: Shift;
  showTimeToShift?: boolean;
};

export const ShiftCard = ({ shift, showTimeToShift }: Props) => {
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  useEffect(() => {
    if (!showTimeToShift) return;
    const interval = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [showTimeToShift]);

  const buildTimer = (shift: Shift, currentTime: number) => {
    return () => {
      const timeDiff = shift.startTime - currentTime;
      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      var timerStr = "";
      if (days > 0) {
        timerStr += `${days} ימים `;
      }
      if (hours > 0) {
        timerStr += `${hours} שעות `;
      }
      if (minutes > 0) {
        timerStr += `${minutes} דקות `;
      }
      timerStr += `ו${seconds} שניות`;
      return timerStr;
    };
  };

  return (
    <Flex
      column
      w100
      align="start"
      justify="center"
      style={{
        padding: "10px",
        border: `1px solid ${COLORS.gray800}`,
        backgroundColor: COLORS.gray50,
        borderRadius: "8px",
        width: "90%",
      }}
    >
      <Flex justify="between" w100>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "16px",
          }}
        >
          {shift.position} - {shift.location}
        </div>
        {showTimeToShift && <Blink color={COLORS.green} size="12px" />}
      </Flex>
      <div>{shift.personnel}</div>
      <Flex
        align="center"
        gap="8px"
        style={{
          fontSize: "14px",
        }}
      >
        <div>{heDays[shift.date.getDay()]}</div>
        <div>{shift.date.toLocaleDateString()}</div>
        <div>{shift.timeDisplay}</div>
      </Flex>
      {showTimeToShift && (
        <Flex align="center" gap="4px" style={{ fontSize: "12px" }}>
          <div>תיהיה שם בעוד</div>
          <div>{buildTimer(shift, currentTime)()}</div>
        </Flex>
      )}
    </Flex>
  );
};
