import React, { useEffect, useState } from "react";
import { fetchShifts, parseShiftData } from "../utils/shiftsUtils";
import { Flex } from "../elements/Flex";

type JusticeShift = {
  julis: number;
  nachinim: number;
  personnel: string;
  total: number;
};

export const Justice = () => {
  const [shiftsPersonInEachLocation, setShiftsPersonInEachLocation] = useState<
    JusticeShift[]
  >([]);

  useEffect(() => {
    // Fetch and parse shifts
    fetchShifts().then((data) => {
      const parsedData = parseShiftData(data);
      const uniquePersons = Array.from(
        new Set(parsedData.map((shift) => shift.personnel))
      );

      const tempShiftsPersonsInEachLocation: JusticeShift[] = [];
      for (let person of uniquePersons) {
        const personShifts = parsedData.filter((shift) =>
          shift.personnel.includes(person)
        );
        const julisCount = personShifts.filter(
          (shift) => shift.location === "גוליס" || shift.location === "ג'וליס "
        ).length;
        const nachinimCount = personShifts.filter(
          (shift) => shift.location === "נחשונים"
        ).length;

        tempShiftsPersonsInEachLocation.push({
          personnel: person,
          julis: julisCount,
          nachinim: nachinimCount,
          total: julisCount + nachinimCount,
        });
      }
      setShiftsPersonInEachLocation(
        tempShiftsPersonsInEachLocation.sort((a, b) => b.total - a.total)
      );
      console.log(
        "tempShiftsPersonsInEachLocation",
        tempShiftsPersonsInEachLocation
      );
    });
  }, []);

  return (
    <Flex column w100>
      <h2>פותחים עיניים</h2>
      <Flex
        column
        gap="8px"
        style={{
          alignItems: "flex-start",
        }}
      >
        <Flex
          gap="8px"
          w100
          style={{
            fontWeight: "bold",
            borderBottom: "1px solid black",
          }}
        >
          <span style={{ width: "50%" }}>שם</span>
          <span style={{ width: "16%" }}>נחשונים</span>
          <span style={{ width: "16%" }}>ג'וליס</span>
          <span style={{ width: "16%" }}>סה"כ</span>
        </Flex>

        {shiftsPersonInEachLocation.map((shift) => (
          <Flex column w100 key={shift.personnel}>
            <Flex gap="8px">
              <span
                style={{
                  width: "50%",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                title={shift.personnel}
              >
                {shift.personnel}
              </span>
              <span style={{ width: "16%" }}>{shift.nachinim}</span>
              <span style={{ width: "16%" }}>{shift.julis}</span>
              <span style={{ width: "16%" }}>{shift.total}</span>
            </Flex>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid black",
              }}
            ></div>
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};
